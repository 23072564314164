<template>
  <div>
    <section>
      <article class="featured_images">
        <img :src="featured_image">
        <div class="featured_images_text">
          <div class="container text-center">
            <h1>{{ $route.query.whereTo }} Safaris and Destinations</h1>
            <p>Safaris Designed for you</p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="container">
        <div>
          <section>
            <article>
              <div class="section_padding">
                <section v-if="$route.query.whereTo">
                  <article>
                    <div class="">
                      <div>
                        <h2>
                          Experience {{ $route.query.whereTo }} Safari Tours & Holidays
                        </h2>
                        <p style="font-size: 0.9em; font-weight: 300;">
                          {{ country_overview }}
                        </p>
                      </div>
                      <div
                        v-if="isLoading"
                        class="px-0 mt-3 col-12"
                      >
                        <div class="products_skeletons">
                          <div
                            v-for="i in 8"
                            :key="i"
                            class="skeleton-block skeleton-effect-wave"
                          ></div>
                        </div>
                      </div>
                      <div v-else>

                        <article
                          v-if="safariPackagesByCountry.length == 0"
                          class="pt-5 pb-5"
                        >
                          <p>No Safari Packages added yet</p>
                        </article>
                        <article v-else>
                          <section>
                            <div class="section_padding text-center">
                              <h2>Our top example tours to {{ $route.query.whereTo }}</h2>
                              <hr style="width: 100px; background-color: #996727;" />
                              <div class="safari_package_grid">
                                <div
                                  v-for="tour of safariPackagesByCountry"
                                  :key="tour.id"
                                >
                                  <div
                                    style="cursor: pointer;"
                                    @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                  >
                                    <img :src="tour.image">
                                  </div>
                                  <div class="package_details_description">
                                    <div>
                                      <span class="tour_title">{{tour.title}}</span><br />
                                      <span>
                                        <small
                                          class="desc_desktop"
                                          :inner-html.prop="tour.brief_description | truncate(120, '...')"
                                        >
                                        </small>
                                        <small
                                          class="desc_mobile"
                                          :inner-html.prop="tour.brief_description | truncate(80, '...')"
                                        >
                                          {{ tour.brief_description | truncate(80, '...')   }}
                                        </small>
                                      </span>
                                    </div>
                                    <div>
                                      <div class="text-center">
                                        <hr style="width: 100px; background-color: #c1aa7f;" />
                                        <span
                                          style="cursor: pointer;"
                                          @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                        >
                                          <small>EXPLORE THIS TRIP</small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section>
                            <article class="destination_section section_padding">
                              <div class="text-center">
                                <h2>Inspiring {{ $route.query.whereTo }} safari & tour destinations</h2>
                                <hr style="width: 100px; background-color: #996727;" />
                                <div class="destination_grid">
                                  <div
                                    v-for="(destination, index) in destinations"
                                    :key="index"
                                  >
                                    <div @click="$router.push({path: `/destination-packages/${$route.query.whereTo}/${destination.id}/${destination.title}`})">
                                      <img
                                        :src="destination.image"
                                        :alt="destination.title"
                                        width="100%"
                                      >
                                      <div class="destination_description">
                                        {{ destination.title }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </section>
                        </article>
                      </div>

                    </div>
                  </article>
                </section>

                <section v-else>
                  <article>
                    <div class="">
                      <div>
                        <h1 style="font-size: 1.5em; font-weight: 600;">African Safari Tours & Holidays</h1>
                        <p style="font-size: 0.9em; font-weight: 300;">
                          Does an African safari feature on your bucket list? It should! Africa has so much to offer,
                          from spectacular scenery, friendly people and cultural treasures to blissful beaches.
                          But above all else, it is the wildlife and safari lifestyle that will draw you back,
                          again and again. This well-known quote by Richard Mullin is so very true: ‘The only man I envy,
                          is the man who has not yet been to Africa – for he has so much to look forward to’.
                          Once you go on your first safari, Africa gets into your blood.
                          You develop a deep longing to return to this magical continent,
                          and to start planning your next African safari holiday.
                          Be warned, Africa is very addictive!
                        </p>
                      </div>
                      <div
                        v-if="isLoading"
                        class="px-0 mt-3 col-12"
                      >
                        <div class="products_skeletons">
                          <div
                            v-for="i in 8"
                            :key="i"
                            class="skeleton-block skeleton-effect-wave"
                          ></div>
                        </div>
                      </div>
                      <div v-else>
                        <article
                          v-if="safari_tours.length == 0"
                          class="pt-5 pb-5"
                        >
                          <p>No Safari Packages added yet</p>
                        </article>
                        <article v-else>
                          <section>
                            <div class="safari_package_grid">
                              <div
                                v-for="tour of safari_tours"
                                :key="tour.id"
                              >
                                <div
                                  style="cursor: pointer;"
                                  @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                >
                                  <img :src="tour.image">
                                </div>

                                <div class="package_details_description">
                                  <div>
                                    <span class="tour_title">{{tour.title}}</span><br />
                                    <span>
                                      <small
                                        class="desc_desktop"
                                        :inner-html.prop="tour.brief_description | truncate(120, '...')"
                                      >
                                      </small>
                                      <small
                                        class="desc_mobile"
                                        :inner-html.prop="tour.brief_description | truncate(80, '...')"
                                      >
                                        {{ tour.brief_description | truncate(80, '...')   }}
                                      </small>
                                    </span>
                                  </div>
                                  <div class="">
                                    <div class="text-center">
                                      <hr style="width: 100px; background-color: #c1aa7f;" />
                                      <span
                                        style="cursor: pointer;"
                                        @click="$router.push({ path: `/package-detail/${tour.title}/${tour.id}` })"
                                      >
                                        <small>EXPLORE THIS TRIP</small>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                    </div>
                  </article>
                </section>

              </div>
            </article>
          </section>
        </div>
      </article>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,

      safari_tours: [],
      safariPackage: {},
      destinations: [],
      countries: [],
      safariPackagesByCountry: [],
      country_overview: "",
      featured_image: "",

      safariPackageForm: {
        destination_country: "",
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },
    };
  },

  watch: {
    "$route.query.whereTo": {
      handler: function (whereTo) {
        if (whereTo != undefined) {
          this.getSafariPackagesByCountry();
        } else {
          this.getSafariPackages();
          this.safariPackageForm.destination_country = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    if (this.$route.query.whereTo) {
      this.safari_tours = [];
      this.getSafariPackagesByCountry();
    } else {
      this.getSafariPackages();
    }
    this.getDestinations();
  },

  methods: {
    handleChange(value) {
      console.log(value);
    },
    async getSafariPackages() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/safari/package/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_tours = request.data.safari_tours;
          this.destinations = request.data.destinations;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async getDestinations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.countries = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },

    changeToACountryTours() {
      this.$router.push({
        path: "/safari-packages",
        query: { whereTo: `${this.safariPackageForm.destination_country}` },
      });
    },

    async getSafariPackagesByCountry() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/tours/where_to?v=${this.$route.query.whereTo}`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safariPackagesByCountry = request.data.safari_tours;
          this.destinations = request.data.destinations;
          this.country_overview = request.data.country_overview;
          this.featured_image = request.data.featured_image;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>


<style scoped>
.featured_images {
  height: 60vh;
  position: relative;
}
.featured_images img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.featured_images .featured_images_text {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

h2 {
  font-size: 2em;
  font-weight: 600;
}
hr {
  margin-bottom: 50px;
}

.section_padding {
  padding-top: 60px;
  padding-bottom: 60px;
}
.packageHeader {
  margin-top: 100px;
}
.tour_title {
  font-size: 1.2em;
  font-weight: 600;
}

.safari_package_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.safari_package_grid > div {
  background-color: #f5f7f8;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.safari_package_grid > div:hover {
  -webkit-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.safari_package_grid > div img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 2s ease;
}
.products_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.products_skeletons > div {
  height: 200px;
  border-radius: 5px;
}

.desc_desktop {
  display: block;
}
.desc_mobile {
  display: none;
}

.package_details_description {
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.destination_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.destination_grid > div {
  background-color: #c1aa7f;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.destination_grid > div img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.destination_grid > div .destination_description {
  padding: 20px 5px;
}

.destination_section {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .packageHeader {
    display: block;
  }
  .safari_package_grid > div {
    padding: 0px 0px 0px 0px;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .safari_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .packageHeader {
    display: block;
  }
  .safari_package_grid > div {
    padding: 0px 0px 0px 0px;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .safari_package_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .desc_desktop {
    display: none;
  }
  .desc_mobile {
    display: block;
  }
}
</style>


<style>
.where_form .el-form-item {
  margin-bottom: 0px;
}

.where_form .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
.where_form label {
  display: inline-block;
  margin-bottom: 0px;
  font-size: 0.7em;
  /* color: white !important; */
}

.where_form .el-select .el-input__inner {
  cursor: pointer;
  padding-right: 35px;
  border: 2px solid #c1aa7f;
  background-color: #c1aa7f;
  color: white !important;
}
</style>